<template>
  <svg
    width="22"
    height="22"
    viewBox="0 0 22 22"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M10.6862 7.56593C10.7849 7.26189 11.2151 7.26189 11.3138 7.56593L11.9138 9.4123C11.958 9.54827 12.0847 9.64033 12.2276 9.64033H14.169C14.4887 9.64033 14.6216 10.0494 14.363 10.2373L12.7924 11.3784C12.6767 11.4625 12.6283 11.6114 12.6725 11.7474L13.2724 13.5937C13.3712 13.8978 13.0232 14.1506 12.7646 13.9627L11.194 12.8216C11.0783 12.7375 10.9217 12.7375 10.806 12.8216L9.23541 13.9627C8.97678 14.1506 8.62881 13.8978 8.72759 13.5937L9.32752 11.7474C9.3717 11.6114 9.3233 11.4625 9.20764 11.3784L7.63702 10.2373C7.37839 10.0494 7.51131 9.64033 7.83099 9.64033H9.77238C9.91535 9.64033 10.042 9.54827 10.0862 9.4123L10.6862 7.56593Z"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M12.4667 21.3976L14.5095 19.381H17.2857C17.8612 19.3817 18.3543 19.1767 18.765 18.766C19.1756 18.3553 19.381 17.8619 19.381 17.2857V14.5095L21.3976 12.4667C21.7992 12.0651 22 11.5762 22 11C22 10.4238 21.7992 9.93492 21.3976 9.53333L19.381 7.49048V4.71429C19.3817 4.13879 19.1767 3.64571 18.766 3.23505C18.3553 2.82438 17.8619 2.61905 17.2857 2.61905H14.5095L12.4667 0.602381C12.0651 0.200794 11.5762 0 11 0C10.4238 0 9.93492 0.200794 9.53333 0.602381L7.49048 2.61905H4.71429C4.13879 2.61835 3.64571 2.82333 3.23505 3.234C2.82438 3.64467 2.61905 4.1381 2.61905 4.71429V7.49048L0.602381 9.53333C0.200794 9.93492 0 10.4238 0 11C0 11.5762 0.200794 12.0651 0.602381 12.4667L2.61905 14.5095V17.2857C2.61835 17.8612 2.82333 18.3543 3.234 18.765C3.64467 19.1756 4.1381 19.381 4.71429 19.381H7.49048L9.53333 21.3976C9.93492 21.7992 10.4238 22 11 22C11.5762 22 12.0651 21.7992 12.4667 21.3976ZM11 17.3684C14.5172 17.3684 17.3684 14.5172 17.3684 11C17.3684 7.48282 14.5172 4.63158 11 4.63158C7.48282 4.63158 4.63158 7.48282 4.63158 11C4.63158 14.5172 7.48282 17.3684 11 17.3684Z"
    />
  </svg>
</template>
